import React, { useEffect, useState, useMemo } from 'react';
import { Modal, ModalHeader, ModalBody, Input, FormGroup, Button, Label, Form, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';
import moment from 'moment';
import { AgGridReact } from '@ag-grid-community/react';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-alpine.css';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import './feedback.css';
import { exportToCSV, exportToExcel, exportToPDF } from '../utils/Exports';

const common = require('../utils/Common');

ModuleRegistry.registerModules([ClientSideRowModelModule]);

const Query = () => {
    const [QueryData, setQueryData] = useState([]);
    const [replyData, setReplyData] = useState([]);
    const [modal, setModal] = useState(false);
    const [modalTwo, setModalTwo] = useState(false);
    const [detailsModal, setDetailsModal] = useState(false);  
    const [QueryReply, setQueryReply] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10); 
    const [selectedDetails, setSelectedDetails] = useState(null);  
    const [gridApi, setGridApi] = useState(null);

    const navigate = useNavigate();

    const toggle = () => setModal(!modal);
    const toggleAnother = () => setModalTwo(!modalTwo);
    const toggleDetails = () => setDetailsModal(!detailsModal);  

    useEffect(() => {
        checkAccess();
    }, []);

    const onGridReady = (params) => {
        setGridApi(params.api);
    };
    
    const checkAccess = async () => {
        let haveAccess = await common.checkAccessRights(window.location.pathname);
        if (!haveAccess) {
            alert("Access Denied");
            navigate("/");
        } else {
            getQuery();
        }
    }

    const getQuery = async () => {
        let currentPage = 0;
        let filterModel = null;
        if (gridApi) {
            currentPage = gridApi.paginationGetCurrentPage();
            filterModel = gridApi.getFilterModel();
        }
        const parameters = {
            source: 'admin',
            'fromDate': $('#Date-from').val(),
            'toDate': $('#Date-to').val(),
        };
        let response = await common.apiRequest('post', '/users/QueryDetails', parameters);
        let data = (response.data && response.data.data) ? response.data.data : [];
        setQueryData(data);
        if (gridApi) {
            setTimeout(() => {
                if (filterModel) {
                    gridApi.setFilterModel(filterModel);
                }
                gridApi.paginationGoToPage(currentPage);
            }, 0);
        }
    };

    const invoiceId = replyData?.order_item?.order?.invoices?.[0]?.id;
    const invoiceNO = replyData?.order_item?.order?.invoices?.[0]?.invoice_no;

    const reply = async () => {
        const currentPage = gridApi.paginationGetCurrentPage();
        const filterModel = gridApi.getFilterModel();
        const parameters = {
            query_id: replyData.id,
            query_reply: QueryReply,
            invoice_id: invoiceId,
            query_reply_by: "Admin",
            source: "admin",
        };

        let response = await common.apiRequest('post', '/users/user-Query', parameters);
        if (response.status === 200) {
            if (response.data.code === "success") {
                alert(response.data.message);
                await getQuery();
                setTimeout(() => {
                    if (filterModel) {
                        gridApi.setFilterModel(filterModel);
                    }
                    gridApi.paginationGoToPage(currentPage);
                }, 0);
            } else {
                alert(response.data.error);
            }
        }
        setModal(false);
    };

    const replyQuery = (data) => {
        setReplyData(data);
        toggle();
    };

    const showQuery = (data) => {
        setReplyData(data);
        toggleAnother();
    };

    const showDetails = (data) => {
        setSelectedDetails(data);  
        toggleDetails();  
    };

    const columnDefs = useMemo(() => [
        { headerName: "S.no", valueGetter: "node.rowIndex + 1", width: 80 },
        { headerName: "User Id", field: "user.reg_id", width: 150 },
        { 
            headerName: "Invoice No", 
            valueGetter: params => {
                const invoices = params.data?.order_item?.order?.invoices || [];
                const sellerProductInvoice = invoices.find(invoice => invoice.invoice_type === 'seller_product' && invoice.seller_id == params.data.seller_id);
                return sellerProductInvoice?.invoice_no || "N/A";
              },
                width: 150 
        },
        { 
            headerName: "User Query", 
            field: "user_query", 
            width: 200 
        },
         { headerName: "Order Id", field: "order_item.order.order_id", width: 150 },
        { headerName: "Order Date", field: "order_item.order.created_at", width: 150 },
        { 
            headerName: "Date", 
            valueGetter: params => moment(params.data.created_at).format('YYYY-MM-DD') || "N/A", 
            width: 150 
        },
        { 
            headerName: "Category", 
            field: "type", 
            width: 150 
        },
        {
            headerName: "Response from vendor",
            field: "vendor_response",
            valueGetter: params => {
                const vendorResponses = params.data.querys_replys
                    .filter(reply => reply.query_reply_by === 'seller')
                    .map(reply => reply.query_reply);
                    
                if (vendorResponses.length === 0) return "No response";
                
                return vendorResponses.join('\n---\n');
            },
            wrapText: true,
            autoHeight: true,
            cellStyle: {
                'white-space': 'pre-wrap',
                'max-height': '200px',
                'overflow-y': 'auto'
            }
        },
        {
            headerName: "Date of response (Vendor)", 
            field: "vendor_response_date",
            valueGetter: params => {
                const vendorResponses = params.data.querys_replys
                    .filter(reply => reply.query_reply_by === 'seller')
                    .map(reply => reply.created_at);
                    
                if (vendorResponses.length === 0) return "N/A";
                
                return vendorResponses.join('\n---\n');
            },
            wrapText: true,
            autoHeight: true,
            cellStyle: {
                'white-space': 'pre-wrap',
                'max-height': '200px',
                'overflow-y': 'auto'
            }
        },
        {
            headerName: "Response from Admin",
            field: "admin_response",
            valueGetter: params => {
                const adminResponses = params.data.querys_replys
                    .filter(reply => reply.query_reply_by === 'Admin')
                    .map(reply => reply.query_reply);
                    
                if (adminResponses.length === 0) return "No response";
                
                return adminResponses.join('\n---\n');
            },
            wrapText: true,
            autoHeight: true,
            cellStyle: {
                'white-space': 'pre-wrap',
                'max-height': '200px',
                'overflow-y': 'auto'
            }
        },
        {
            headerName: "Date of response (Admin)", 
            field: "vendor_response_date",
            valueGetter: params => {
                const adminResponses = params.data.querys_replys
                    .filter(reply => reply.query_reply_by === 'Admin')
                    .map(reply => reply.created_at);
                    
                if (adminResponses.length === 0) return "N/A";
                return adminResponses.join('\n---\n');
            },
            wrapText: true,
            autoHeight: true,
            cellStyle: {
                'white-space': 'pre-wrap',
                'max-height': '200px',
                'overflow-y': 'auto'
            }
        },
        { 
            headerName: "Reply", 
            field: "reply", 
            cellRenderer: (params) => (
                <button className='btn btn-primary btn-sm' onClick={() => replyQuery(params.data)}>View</button>
            ),
            width: 100 
        },
        // { 
        //     headerName: "Details",  // New Details column
        //     cellRenderer: (params) => (
        //         <button className='btn btn-secondary btn-sm' onClick={() => showDetails(params.data)}>Details</button>
        //     ),
        //     width: 100 
        // }
    ], []);

    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: true,
        floatingFilter: true,
        resizable: true,
    }), []);

    const rowData = QueryData.map((data, index) => {
        const vendorResponses = data.querys_replys?.filter(reply => reply.query_reply_by === 'seller') || [];
        const adminResponses = data.querys_replys?.filter(reply => reply.query_reply_by === 'Admin') || [];
    
        return {
            ...data,
            vendorResponses: vendorResponses.length > 0 
                ? vendorResponses.map(r => r.query_reply).join('\n---\n')
                : "No response",
            vendorResponseDates: vendorResponses.length > 0
                ? vendorResponses.map(r => r.created_at).join('\n')
                : "N/A",
            adminResponses: adminResponses.length > 0
                ? adminResponses.map(r => r.query_reply).join('\n---\n')
                : "No response",
            adminResponseDates: adminResponses.length > 0
                ? adminResponses.map(r => r.created_at).join('\n')
                : "N/A"
        };
    });
    
    const data = QueryData.map((data, index) => ({
        "Reg id": data.user.reg_id,
        "Invoice no": data.order_item?.order?.invoices?.find(invoice => invoice.invoice_type === 'seller_product' && invoice.seller_id == data.seller_id)?.invoice_no,
        "User Query": data.user_query,
        "Created at": data.created_at,
        "Type": data.type,
        "Vendor Response": rowData[index]?.vendorResponses,
        "Vendor Response Date": rowData[index]?.vendorResponseDates,
        "Admin Response": rowData[index]?.adminResponses,
        "Admin ResponseDate": rowData[index]?.adminResponseDates,
    }));


const handleExportCSV = () => {
    exportToCSV(data, 'Admin Query');
}

const handleExportExcel = () => {
    exportToExcel(data, 'Admin Query');
}

const handleExportPDF = () => {
    exportToPDF(data, 'Admin Query');
}
    return (
        <>
            <div className='Query-component'>
                <div>
                    <div className='Query-header col-md-12'>
                        <h2>Users Query</h2>
                    </div>
                    <div className="col-md-12">
                        <Form className='row'>
                            <FormGroup className='col-md-4 row'>
                                <Label className="col-md-2 col-form-label" for="Date-from">From:</Label>
                                <input type='date' defaultValue={moment().startOf('month').format('YYYY-MM-DD')} onChange={getQuery} className="form-control col-md-5" id="Date-from" name="Date-from"></input>
                            </FormGroup>
                            <FormGroup className='col-md-4 row'>
                                <Label className=" col-md-2 col-form-label" for="Date-to">To:</Label>
                                <input type='date' defaultValue={moment().endOf('month').format('YYYY-MM-DD')} onChange={getQuery} className="form-control col-md-5" id="Date-to" name="Date-to"></input>
                            </FormGroup>
                        </Form>
                    </div>
                    <button className='btn button-product' onClick={handleExportCSV}>Export to CSV</button>
                        <button className='btn button-product' onClick={handleExportExcel}>Export to Excel</button>
                        <button className='btn button-product' onClick={handleExportPDF}>Export to PDF</button>
                    <div className='ag-theme-alpine' style={{ height: 500, width: '100%' }}>
                        <AgGridReact
                            rowData={rowData}
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            pagination={true}
                            paginationPageSize={10}
                            onGridReady={onGridReady}
                            suppressPaginationPanel={false}
                        />
                    </div>
                </div>
            </div>

            <Modal isOpen={modal} toggle={toggle} size="xl">
                <ModalHeader toggle={toggle}>Query reply</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Label for="reply">Reply Query</Label>
                            <Input
                                type='text'
                                id="Query_reply"
                                value={QueryReply}
                                onChange={(e) => setQueryReply(e.target.value)}
                            />
                        </FormGroup>
                    </Form>
                    <Button className='submit' onClick={reply}>
                        Submit
                    </Button>
                </ModalBody>
            </Modal>

            <Modal isOpen={detailsModal} toggle={toggleDetails} size="xl">
                <ModalHeader toggle={toggleDetails}>Details</ModalHeader>
                <ModalBody>
                    {selectedDetails && (
                        <table className='table table-bordered'>
                            <thead>
                                <tr>
                                    <th>S. No.</th>
                                    <th>Invoice No</th>
                                    <th>Item/Particular</th>
                                    <th>Online Shopping Taxable Amount</th>
                                    <th>Online Shopping Total Amount</th>
                                    <th>Offline Shopping Taxable Amount</th>
                                    <th>Offline Shopping Total Amount</th>
                                    <th>Online Sales Taxable Amount</th>
                                    <th>Online Sales Total Amount</th>
                                    <th>Offline Sales Taxable Amount</th>
                                    <th>Offline Sales Total Amount</th>
                                    <th>Commission Amount</th>
                                    <th>TDS</th>
                                    <th>Net Receivable</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{selectedDetails.sno || 'N/A'}</td>
                                    <td>{selectedDetails?.order_item?.order?.invoices?.[0]?.invoice_no || 'N/A'}</td>
                                    <td>{selectedDetails.item || 'N/A'}</td>
                                    <td>{selectedDetails.onlineShoppingTaxableAmount || 'N/A'}</td>
                                    <td>{selectedDetails.onlineShoppingTotalAmount || 'N/A'}</td>
                                    <td>{selectedDetails.offlineShoppingTaxableAmount || 'N/A'}</td>
                                    <td>{selectedDetails.offlineShoppingTotalAmount || 'N/A'}</td>
                                    <td>{selectedDetails.onlineSalesTaxableAmount || 'N/A'}</td>
                                    <td>{selectedDetails.onlineSalesTotalAmount || 'N/A'}</td>
                                    <td>{selectedDetails.offlineSalesTaxableAmount || 'N/A'}</td>
                                    <td>{selectedDetails.offlineSalesTotalAmount || 'N/A'}</td>
                                    <td>{selectedDetails.commissionAmount || 'N/A'}</td>
                                    <td>{selectedDetails.tds || 'N/A'}</td>
                                    <td>{selectedDetails.netReceivable || 'N/A'}</td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                </ModalBody>
            </Modal>
        </>
    );
};

export default Query;
