import React from 'react';

const FileViewer = ({ fileUrl, fileType }) => {
  if (!fileUrl) {
    return (
      <div className="flex items-center justify-center p-4 border border-dashed border-gray-300 rounded-lg">
        <p className="text-gray-500">No file uploaded</p>
      </div>
    );
  }

  if (fileType === 'pdf') {
    return (
      <object
        data={fileUrl}
        type="application/pdf"
        className="w-full h-900 border border-gray-200 rounded-lg"
      >
        <div className="flex items-center justify-center p-4 bg-gray-50 rounded-lg">
          <p className="text-gray-700">
            Unable to display PDF directly. {' '}
            <a 
              href={fileUrl} 
              target="_blank" 
              rel="noopener noreferrer"
              className="text-blue-600 hover:text-blue-800"
            >
              Click here to open
            </a>
          </p>
        </div>
      </object>
    );
  }

  return (
    <img
      src={fileUrl}
      alt="Uploaded file"
      className="w-4/5 h-auto object-contain rounded-lg"
    />
  );
};

export default FileViewer;