import React,{useEffect, useState} from 'react'
import "./nav.css";
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
const common = require('../utils/Common');
const NavBar = () => {
let navigate = useNavigate();
const [sidebarVisible, setSidebarVisible] = useState(false);

const logOut = async () => {

  if (localStorage.getItem('token')) {
    let data = {
      "access_token" : localStorage.getItem('token')
    }
    let response = await common.apiRequest('post', '/admin/logout', data);
    if (response.status == 200) {
      if (response.data.code == 'success') {
        localStorage.removeItem('token');
        localStorage.removeItem("userId");
        sessionStorage.removeItem("access_rights");
        alert(response.data.message);
      } else {
        alert(response.data.error);
      }
    }
    else {
      alert('Something went wrong');
    }
  }
  navigate('login');
}

  const toggleSidebar = () => {
    setSidebarVisible(prevState => !prevState);
  };

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      navigate('/login');
    }    
  })
  return (
    <>
    
<nav className="navbar navbar-expand-lg bg-light">
  <div className="container-fluid">
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item">
        <Link className="nav-link active" to="/">Home</Link>
        </li>
        <li className="nav-item">
        <Link className="nav-link active" to="/service">Service</Link>
        </li>
        <li className="nav-item">
        <Link className="nav-link active" to="/category">Category</Link>
        </li>
        <li className="nav-item">
        <Link className="nav-link active" to="/Srrr-report">SRRR Report</Link>
        </li>
        <li className="nav-item dropdown">
          <Link className="nav-link dropdown-toggle" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          Admin Links
          </Link>
          <ul className="dropdown-menu">
          <li><Link className="dropdown-item" to="/">Home</Link></li>
          <li><Link className="dropdown-item" to="/category" classNameName='navitem'>Category</Link></li>
          <li><Link className="dropdown-item" to="/cms">Poilicy Managment System</Link></li>
          <li><Link className="dropdown-item" to="/service">Service</Link></li>
          <li><Link className="dropdown-item" to="/portals">Add Portals</Link></li>
          <li><Link className="dropdown-item" to="/user-types">Add User Types</Link></li>
          <li><Link className="dropdown-item" to="/admin-user-creation">Admin User Creations</Link></li>
          {/* <li><Link className="dropdown-item" to="/sellers-products">Approve product</Link></li> */}
          <li><Link className="dropdown-item" to="/discount-apply">Apply Discount</Link></li>
          <li><Link className="dropdown-item" to="/system-config">System Config</Link></li>
          <li><Link className="dropdown-item" to="/email-templates">Email Template</Link></li>
          <li><Link className='dropdown-item' to="/user-feedback">Feedback</Link></li>
          <li><Link className='dropdown-item' to="/user-query">Query</Link></li>
          {/* <li><Link className='dropdown-item' to="/seller-service-approve">Seller service approve</Link></li> */}
          <li><Link className='dropdown-item' to="/buyer-commissions">Buyer Commissions</Link></li>
          <li><Link className='dropdown-item' to="/seller-payments">Seller Payments</Link></li>
          <li><Link className='dropdown-item' to="/Buyers">Buyers</Link></li>
          <li><Link className='dropdown-item' to="/Sellers">Sellers</Link></li>
          <li><Link className='dropdown-item' to="/pincodes">Pincodes</Link></li>
          </ul>
        </li>
        <li className="nav-item dropdown">
          <Link className="nav-link dropdown-toggle" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Reports
          </Link>
          <ul className="dropdown-menu">
          <li><Link className="dropdown-item" to="/">Home</Link></li>
          <li><Link className="dropdown-item" to="/BankReports" classNameName='navitem'>BankReports</Link></li>
          <li><Link className="dropdown-item" to="/LogisticsReports">LogisticsReports</Link></li>
          <li><Link className="dropdown-item" to="/PaymentReports">PaymentReports</Link></li>
          <li><Link className="dropdown-item" to="/ReceiptReports">ReceiptReports</Link></li>
          <li><Link className="dropdown-item" to="/ServiceReports">ServiceReports</Link></li>
          <li><Link className="dropdown-item" to="/TdsReports">TdsReports</Link></li>
          <li><Link className="dropdown-item" to="/VendorReports">VendorReports</Link></li>
          </ul>
        </li>
      </ul>
      <form className="d-flex" role="search">
      <Button  onClick={logOut} classNameName='navitem'>
                          Logout
                    </Button>
      </form>
    </div>
  </div>
</nav>
    </>
  )
}

export default NavBar