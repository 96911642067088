import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { useNavigate, useLocation } from 'react-router-dom';
import { exportToCSV, exportToExcel, exportToPDF } from '../utils/Exports';
import moment from 'moment';
import { FormGroup, Label, Form, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Table } from 'reactstrap';
import { parseValue } from '../utils/Common';

const common = require('../utils/Common');

const SellersBuyersOrders = () => {
    let navigate = useNavigate();
    const location = useLocation();
    const [allOrders, setAllOrders] = useState([]);
    const [orderStatuses, setOrderStatuses] =useState([]);

	useEffect(() => {
        checkAccess();
	}, []);

    const checkAccess = async () => {
        let haveAccess = await common.checkAccessRights(window.location.pathname);
        if (!haveAccess) {
          alert("Access Denied");
          navigate("/");
        } else {
            if (!localStorage.getItem('token')) {
                navigate('/login');
            }
            if (!location.state) {
                navigate('/');
            }
            getAllOrders();
            setOrderStatuses(await common.getSystemConfig('product_service_order_status'));
        }
    }

    const getAllOrders = async () => {
        let data = {
            'seller_id' : location.state,
            'start_date': $('#Date-from').val(),
            'end_date': $('#Date-to').val(),
        }
        let response = await common.apiRequest('post', '/seller/get-orders', data);
        if (response && response.data && response.data.code == 'success') {
            setAllOrders(response.data.data);
        } else {
            setAllOrders([]);
        }
    }

    const changeOrderStatus = async (orderId, orderStatus, orderPrice, userId) => {
        let data = {
            'action': 'update',
            'order_id': orderId,
            'order_status' : orderStatus,
            'order_price': orderPrice,
            'user_id': userId
        }
        let response = await common.apiRequest('post', '/orders/change-order-status-operations', data);
        if (response.data.code == 'success') {
            alert(response.data.message.replaceAll("_", " "));
            getAllOrders();
        } else {
            alert(response.data.error, 'error');
        }
    }
    const allOrdersData = allOrders.map((order, index) => {
        return {
            "S. No.": index + 1,
            "Order ID": order?.order?.order_id,
            "Sale Type": order?.product_item 
                            ? "Online Product Sale" 
                            : order?.seller_service 
                            ? "Online Service Sale" 
                            : "Offline Product Sale",
            "Order Role": 
                (order?.logistics_vendor_id == localStorage.getItem('userId') && order?.seller_id == localStorage.getItem('userId')) ||
                (order?.offline_sale_product_datum && order?.offline_sale_product_datum.offline_sale && 
                 order?.offline_sale_product_datum.offline_sale.seller_id == localStorage.getItem("userId")) 
                    ? "Both" 
                    : order?.logistics_vendor_id == localStorage.getItem('userId') 
                    ? "Logistics" 
                    : "Seller",
            "Product/Service Name": 
                order?.product_item 
                    ? order.product_item.product.name 
                    : order?.seller_service 
                    ? order.seller_service.service_name 
                    : order?.offline_sale_product_datum 
                    ? order.offline_sale_product_datum.product_description 
                    : "NA",
            "Product/Service Description": 
                order?.product_item 
                    ? order.product_item.product.description 
                    : order?.seller_service 
                    ? order.seller_service.service_description 
                    : order?.offline_sale_product_datum 
                    ? order.offline_sale_product_datum.product_description 
                    : "NA",
            "Order Price": parseValue(order?.order_price,'float'),
            "Order Quantity": order?.order_quantity,
            "Order Status": order?.order_status,
            "Order Completed On": order?.order_completed_on,
            "Order Cancelled On": order?.order_cancelled_on,
            "Payment ID": order?.payment_id,
            "Payment Status": order?.payment_status,
            "Total Shopping Taxable Amount": parseValue(order?.total_order_price,'float'),
            "Total Sales Amount": parseValue(order?.total_sales,'float'),
            "Order date": order?.created_at,
            "Taxable Amount": parseValue(order?.taxable_amount,'float'),
            "Tax Percent": order?.tax_percent,
            "CGST Percent": order?.cgst_percent,
            "CGST Amount": parseValue(order?.cgst,'float'),
            "SGST Percent": order?.sgst_percent,
            "SGST Amount": parseValue(order?.sgst,'float'),
            "IGST Percent": order?.igst_percent,
            "IGST Amount": parseValue(order?.igst,'float'),
        };
    });
    
    const handleExportCSVs = () => {
        exportToCSV(allOrdersData, 'seller order');
    }
    
    const handleExportExcels = () => {
        exportToExcel(allOrdersData, 'seller order');
    }
    
    const handleExportPDFs = () => {
        exportToPDF(allOrdersData, 'seller order');
    }
    return (
        <>
        <h1>All Orders</h1><br/>
        <Form className='row'>
                    <FormGroup className='col-md-4 row'>
                        <Label className="col-md-2 col-form-label" for="Date-from">From:</Label>
                        <Input type='date' defaultValue={moment().startOf('month').format('YYYY-MM-DD')} onChange={getAllOrders} className="form-control col-md-5" id="Date-from" name="Date-from"></Input>
                    </FormGroup>
                    <FormGroup className='col-md-4 row'>
                        <Label className="col-md-2 col-form-label" for="Date-to">To:</Label>
                        <Input type='date' defaultValue={moment().endOf('month').format('YYYY-MM-DD')} onChange={getAllOrders} className="form-control col-md-5" id="Date-to" name="Date-to"></Input>
                    </FormGroup>
                </Form>
        <button className='btn button-product' onClick={handleExportCSVs}>Export to CSV</button>
        <button className='btn button-product' onClick={handleExportExcels}>Export to Excel</button>
        <button className='btn button-product' onClick={handleExportPDFs}>Export to PDF</button>

        <div className="table-responsive">
                <table className="table table-bordered">                
                <thead>
                    <tr className='table-primary'>
                        <th>Order ID</th>
                        <th>Order Type</th>
                        <th>Order date</th>
                        <th>Seller/Logistics/Both Order</th>
                        <th>Product Name</th>
                        <th>Product Description</th>
                        <th>Order Price</th>
                        <th>Order Quantity</th>
                        <th>Order Status</th>
                        <th>Order Completed On</th>
                        <th>Order Cancelled On</th>
                        <th>Payment ID</th>
                        <th>Payment Status</th>
                        <th>Taxable Amount</th>
                        <th>Tax Percent</th>
                        <th>CGST Percent</th>
                        <th>CGST Amount</th>
                        <th>SGST Percent</th>
                        <th>SGST Amount</th>
                        <th>IGST Percent</th>
                        <th>IGST Amount</th>
                    </tr>
                </thead>
                <tbody className='table-body'>
                    {
                        allOrders.length == 0 ?
                        <tr>
                            <td align="center" colspan="12">
                                <center>No Orders Available</center>
                            </td>
                        </tr>
                        :
                        allOrders.map((order)=> {
                            return (
                            <tr>
                                <td>{order.order.order_id}</td>
                                <td>{order.product_item ? "Online Product Sale" : order.seller_service ? "Online Service Sale" : "Offline Product Sale"}</td>
                                <td>{order.order.created_at}</td>
                                <td>{(order.logistics_vendor_id == localStorage.getItem('userId') && order.seller_id == localStorage.getItem('userId')) || (order.offline_sale_product_datum && order.offline_sale_product_datum.offline_sale && order.offline_sale_product_datum.offline_sale.seller_id == localStorage.getItem("userId")) ? "Both" : order.logistics_vendor_id == localStorage.getItem('userId') ? "Logistics" : "Seller"} Order</td>
                                <td>{order.product_item ? order.product_item.product.name : order.seller_service ? order.seller_service.service_name : order.offline_sale_product_datum ? order.offline_sale_product_datum.product_description : "NA"}</td>
                                <td>{order.product_item ? order.product_item.product.description : order.selelr_service ? order.seller_service.service_description : order.offline_sale_product_datum ? order.offline_sale_product_datum.product_description : "NA"}</td>
                                {/* <td><img src={envConfig.api_url + (order.product_item ? order.product_item.product_image.split()[0] : order.seller_service.service_image)} style={{ 'height' : '200px', 'width' : '200px'}}/></td> */}
                                <td>{order.order_price}</td>
                                <td>{order.order_quantity}</td>
                                <td>
                                    <>
                                        <select id="order_status" value={order.order_status} className="form-control" onChange={(e) => changeOrderStatus(order.id, e.target.value, order.order_price, order.order.user_id)} style={{width: '130px'}}>
                                            {
                                                orderStatuses && orderStatuses.product && (order.product_item || order.offline_sale_product_datum) ? Object.entries(orderStatuses.product).map(([key, value]) => (
                                                    <option key={key} value={key}>{value}</option>
                                                ))
                                                :
                                                orderStatuses && orderStatuses.service && order.seller_service ? Object.entries(orderStatuses.service).map(([key, value]) => (
                                                    <option key={key} value={key}>{value}</option>
                                                )) : null
                                            }
                                        </select>
                                    </>
                                </td>
                                <td>{order.order_completed_on}</td>
                                <td>{order.order_cancelled_on}</td>
                                <td>{order.payment_id}</td>
                                <td>{order.payment_status}</td>
                                <td>{parseValue(order.taxable_amount,'float')}</td>
                                <td>{order.tax_percent}</td>
                                <td>{parseValue(order.cgst_percent,'float')}</td>
                                <td>{parseValue(order.cgst,'float')}</td>
                                <td>{order.sgst_percent}</td>
                                <td>{parseValue(order.sgst,'float')}</td>
                                <td>{order.igst_percent}</td>
                                <td>{parseValue(order.igst,'float')}</td>
                            </tr>
                            );
                        })
                    }
                </tbody>
            </table>
            </div>

        </>
    )
}

export default SellersBuyersOrders;